import axios from '@/auth/http'

export default {
  state: {
    user: null,
  },
  getters: {
    userInfo(state) {
      return state.user
    }
  },
  mutations: {
    setUserData(state, user) {
      localStorage.setItem('user', JSON.stringify(user))
      state.user = user
    },
    fetchUser(state) {
      state.user = JSON.parse(localStorage.getItem('user'))
    },
  },
  actions: {
    fetchLocalUser({ commit }) {
      commit('fetchUser')
    },
    fetchUser({ commit }) {
      // Network request inside promise and reolve response
      return new Promise((resolve, reject) => {
        axios.get('/user/session')
          .then(response => {
            commit('setUserData', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateUser({ commit, dispatch }, payload) {
      // Network request inside promise and reolve response
      return new Promise((resolve, reject) => {
        axios.patch('/user', payload)
          .then(response => {
            dispatch('fetchUser')
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })

    },
  },
}
