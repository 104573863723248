import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: '/my-accounts',
      name: 'home',
      // component: () => import('@/views/Home.vue'),
      // meta: {
      //   pageTitle: 'Home',
      //   breadcrumb: [
      //     {
      //       text: 'Home',
      //       active: true,
      //     },
      //   ],
      // },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/profile/Profile.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Profile',
        breadcrumb: [
          {
            text: 'Home',
            to: '/my-accounts'
          },
          {
            text: 'Profile',
            active: true,
          },
        ],
      },
    },
    {
      path: '/my-accounts',
      name: 'my-accounts',
      component: () => import('@/views/accounts/AllAccounts.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'My Accounts',
        breadcrumb: [
          {
            text: 'Home',
            to: '/my-accounts'
          },
          {
            text: 'Accounts',
            active: true,
          },
        ],
      },
    },
    {
      path: '/buy-account',
      name: 'buy-account',
      component: () => import('@/views/accounts/BuyNewAccount.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'New Account',
        breadcrumb: [
          {
            text: 'Home',
            to: '/my-accounts'
          },
          {
            text: 'Accounts',
            to: '/my-accounts'
          },
          {
            text: 'New Account',
            active: true,
          },
        ],
      },
    },
    {
      path: '/charges',
      name: 'charges',
      component: () => import('@/views/charges/Charges.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Charges',
        breadcrumb: [
          {
            text: 'Home',
            to: '/my-accounts',
          },
          {
            text: 'Charges',
            active: true,
          },
        ],
      },
    },
    {
      path: '/orders',
      name: 'orders',
      component: () => import('@/views/orders/Orders.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Placed Orders',
        breadcrumb: [
          {
            text: 'Home',
            to: '/my-accounts',
          },
          {
            text: 'Orders',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/auth/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/set-token',
      name: 'login',
      component: () => import('@/views/auth/SetToken.vue')
    },
    {
      path: '/signup',
      name: 'signup',
      component: () => import('@/views/auth/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/terms-and-conditions',
      name: 'terms-and-conditions',
      component: () => import('@/views/termsAndConditions/TermsAndConditions.vue'),
      meta: {
        // requiresAuth: true,
        layout: 'full',
        pageTitle: 'Terms And Conditions',
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/auth/ForgotPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import('@/views/auth/ResetPassword.vue'),
      props: true,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  // if meta contains requiresAuth, check if user is logged in
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!localStorage.getItem('token')) {
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      })
    } else if (localStorage.getItem('actions')) {
      next({
        path: '/terms-and-conditions'
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
