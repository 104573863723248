import axios from '@/auth/http'

export default {
  state: {
    accounts: [],
    languages: {},
    groups: [],
    orders: [],
    transactions: [],
    charges: []
  },
  getters: {
    orderInfo (state) {
      return state.orders
    }
  },
  mutations: {
    setLanguages(state, languages) {
      state.languages = languages
    },
    setAccounts(state, accounts) {
      state.accounts = accounts
    },
    setGroups(state, groups) {
      state.groups = groups
    },
    setOrders(state, orders) {
      state.orders = orders
    },
    setTransactions(state, transactions) {
      state.transactions = transactions
    },
    setCharges (state, charges) {
      state.charges = charges
    }
  },
  actions: {
    fetchAccounts({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('ppc/accounts')
          .then(response => {
            commit('setAccounts', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    async fetchInvoices() {
      return axios.get('/ppc/invoices')
    },
    fetchGroups({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('ppc/groups')
          .then(response => {
            commit('setGroups', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchOrders({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('ppc/orders')
          .then(r => {
            commit('setOrders', r.data)
            resolve(r)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchTransactions( { commit }) {
      return new Promise((resolve, reject) => {
        axios.get('ppc/transactions')
          .then(r => {
            commit('setTransactions', r.data)
            resolve(r)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    async fetchCharges ({ commit }, clientsAccountsIds) {
      try {
        var combinedCharges = []
        for (let i = 0; i < clientsAccountsIds.length; i++) {
          const accountId = clientsAccountsIds[i];
          const resp = await axios.get(`/ppc/accounts/${accountId}/charges`)
          if (resp.data.length) {
            combinedCharges.push(resp.data)
          }
        }
        combinedCharges = combinedCharges.flat(Infinity)
        commit('setCharges', combinedCharges)
      } catch (error) {
        console.log('errorr: ', error)
      }
    },
    fetchAllLanguages({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/filters/languages/')
          .then(response => {
            const languages = []
            Object.keys(response.data).forEach(key => {
              languages.push({
                title: response.data[key],
                value: key,
              })
            })
            commit('setLanguages', languages)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    buyNewAccount({}, payload) {
      return new Promise((resolve, reject) => {
        axios.post('ppc/orders', payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fundAccount({}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`ppc/deposit`, { amount: payload.amount })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    terminateAccount({}, accountId) {
      return new Promise((resolve, reject) => {
        axios.post(`/ppc/accounts/${accountId}/terminate`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  },
}
