import axios from 'axios'

const axiosConfig = {
  baseURL: 'https://toolbox.aphex.me',
  timeout: 30000,
}

const http = axios.create(axiosConfig)

http.interceptors.request.use(config => {
  const configCopy = { ...config }
  if (localStorage.getItem('token')) {
    configCopy.headers.Authorization = `${localStorage.getItem('token')}`
  }
  return config
})

http.interceptors.response.use(response => response, error => {
  if (error.response.status === 401) {
    if (error.request.responseURL.includes('/login')
      || error.request.responseURL.includes('/register')
      || error.request.responseURL.includes('/forgot-password')) {
      return Promise.reject(error)
    }
    localStorage.removeItem('token')
    window.location.href = '/login'
  }
  return Promise.reject(error)
})

export default http
