import axios from '@/auth/http'

export default {
  state: {},
  getters: {},
  mutations: {
    setToken(state, token) {
      localStorage.setItem('token', token)
      axios.defaults.headers.common.Authorization = `${token}`
    },
    setActions(state, actionsNeeded) {
      localStorage.setItem('actions', actionsNeeded)
    }
  },
  actions: {
    loginUser({ commit, dispatch }, payload) {
      // Network request inside promise and resolve response
      return new Promise((resolve, reject) => {
        axios.post('/user/login', {
          ...payload,
          operator: 'ppc.aphex.me',
        })
          .then(response => {
            if (response.data.success) {
              response.data.actions.includes('confirm-terms') ? commit('setActions', 'confirm-terms') : commit('setActions', '')
              commit('setToken', response.data.token.token)
              dispatch('fetchUser')
              resolve(response)
            } else {
              reject(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    createUser({ commit, dispatch }, payload) {
      // Network request inside promise and reolve response
      return new Promise((resolve, reject) => {
        axios.post('/clients', {
          ...payload,
          operator: 'ppc.aphex.me',
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    sendPasswordResetCode({}, payload) {
      // Network request inside promise and reolve response
      return new Promise((resolve, reject) => {
        axios.post('/users/reset-password', {
          ...payload,
          operator: 'ppc.aphex.me',
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    resetPassword({}, payload) {
      // Network request inside promise and reolve response
      return new Promise((resolve, reject) => {
        axios.patch('/users/password', {
          ...payload,
          domain: 'ppc.aphex.me',
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    acceptTermsAndConditions({}, payload) {
      return new Promise((resolve, reject) => {
        axios.patch('/user', payload)
          .then(response => {
            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    }
  },
}
